import styles from '@styles/components/honey-slider/index.module.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import Link from "next/link";
import {Navigation, Pagination, EffectFade} from 'swiper';
import React, {useRef} from "react";
import {useInView} from "framer-motion";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

// @ts-ignore
export const HoneyListSlider = ({Collection, index}) => {
    // Animation of image when scrolling and in view
    const ref = useRef(null);
    const isInView = useInView(ref, {once: false});

    // Configuration for swiper
    const swiperConfig = {
        modules: [Navigation, Pagination, EffectFade],
        spaceBetween: 70,
        centeredSlides: true,
        centeredSlidesBounds: true,
        initialSlide: 0,
        loop: true,
        fade: "true",
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 70,
            },
            481: {
                slidesPerView: 2,
                spaceBetween: 0,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 70,
            },
            1025: {
                slidesPerView: Collection.Link == "honey-packet" ? 3.85 : 3.85,
                spaceBetween: 0,
            },
            1201: {
                slidesPerView: Collection.Link == "honey-packet" ? 3.85 : 3.85,
                spaceBetween: 0,
            }
        }
    }

    /***
     * This section contains code of the honey items in horizontal scroll.
     * Used swiper for horizontal infinite scroll.
     * It contains 3 title, and the css are inherited from root style and for inner image ,title and line
     * the style is defined in the separate file of this component.
     */
    return (
        <section className={`product-container ${styles.honey_list_slider} ${index !=0 ? styles.seperator_spacing : ''}`}>
            <div className={'titles'}>
                <h3 className={'title-bold'}>{Collection.Title}</h3>
                <span className={'title-normal'} dangerouslySetInnerHTML={{__html: Collection.Sub_Description}}></span>
            </div>
            <Swiper {...swiperConfig} slidesPerView={'auto'} ref={ref}>
                {
                    Collection.products.map((honey: any, index: number) => {
                        return (<SwiperSlide key={'honey-' + index} className={`${styles.honey_item}`}>
                            <Link href={`/products/detail/${Collection.Link}/${honey.Shopify_Link}`}>
                                <img className={`${isInView ? 'slide-up-animation-active' : 'slide-up-animation'} ${Collection.Link == 'honey-packet'? styles.img_honeypacket : ''}`}
                                     loading={"lazy"}
                                     src={honey.Image.url} alt={honey.Image.alternativeText}/>
                                <div className={styles.bar_line}></div>
                                <span>{honey.Title}</span>
                            </Link>
                        </SwiperSlide>)
                    })
                }
                <div className={`swiper-button-prev ${styles.swiper_button_prev_next}`} tabIndex={0} role="button"
                     aria-label="Previous slide">
                    <img src="/assets/images/decorations/extra/left_arrow.png" alt="previous"/>
                </div>
                <div className={`swiper-button-next ${styles.swiper_button_prev_next}`} tabIndex={0} role="button"
                     aria-label="Next slide">
                    <img src="/assets/images/decorations/extra/right_arrow.png" alt="next"/>
                </div>
                <div className={'swiper-pagination'}>
                    {
                        Collection.products.map((_:any, index:number) => {
                            return (<span key={'dot-honey-' + index} className="swiper-pagination-bullet" tabIndex={0}
                                          role="button"
                                          aria-label={`Go to slide ${index}`}></span>)
                        })
                    }
                </div>
            </Swiper>
        </section>
    )
}
